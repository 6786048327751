<script>
	import { PUBLIC_DASHBOARD_BASE_URL } from '$env/static/public';
</script>

<div
	class="card card-bordered card-compact border-2 border-base-100 bg-base-200/85 backdrop-blur-sm shadow-xl max-w-screen-sm"
>
	<div class="card-body gap-0 prose">
		<strong class="pr-10">Thank You!</strong>
		<p>
			We are very grateful to you for believing in EasyPageGo! You won't be disappointed, I promise!
		</p>
		<p>
			Your payment is being processed, in a few minutes <strong>you will receive an email</strong> that
			will allow you to access your private dashboard securely.
		</p>
		<p>
			At any time, you can go to the <a
				href={PUBLIC_DASHBOARD_BASE_URL}
				target="_blank"
				class="link-primary link-hover">dashboard</a
			>
			or <a href="mailto:hello@easypagego.com" class="link-info link-hover">ask for support</a>.
		</p>
	</div>
</div>
